"use client";

import { useAppDispatch, useAppSelector } from "@/store";
import {
  selectIsLoggedIn,
  selectIsSender,
  selectShowTrialsModal,
  selectUserPayment,
  selectUserPlan,
  setShowTrialsModal,
} from "@/store/slices/auth.slice";
import { Grid, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import {
  TrialsClosed,
  TrialsContainer,
  TrialsContentContainer,
  TrialsGridWrapper,
  TrialsIcon,
  TrialsIconContainer,
} from "@/components/shared/trials/trials.styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TrialsHeader } from "@/components/shared/trials/components/TrialsHeader";
import { TrialsFeatures } from "@/components/shared/trials/components/TrialsFeatures";
import { TrialsTerms } from "@/components/shared/trials/components/TrialsTerms";
import { TrialsCta } from "@/components/shared/trials/components/TrialsCta";
import { trialsModalPageAllowed } from "@/common/constants/pageSpecifics";
import { usePathname } from "next/navigation";
import theme from "@/styles/theme";
import routes from "@/common/constants/routes";
import { useRouter } from "next/navigation";
import { selectBasicTransfer } from "@/store/slices/workspace.slice";

interface UserPlan {
  planName: string;
}

interface UserPayment {
  customerKey: string;
}

const TrialsModal = () => {
  const dispatch = useAppDispatch();
  const pathName = usePathname();
  const router = useRouter();
  const showTrialsModal = useAppSelector(selectShowTrialsModal);
  const [open, setOpen] = useState(false);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const plan = useAppSelector(selectUserPlan) as UserPlan;
  const payment = useAppSelector(selectUserPayment) as UserPayment;
  const isSender = useAppSelector(selectIsSender);
  const extractedPath = pathName
    .split(routes.HOME)
    .slice(0, 2)
    .join(routes.HOME);
  const pageAllowed = trialsModalPageAllowed.includes(extractedPath);
  const basicTransfer = useAppSelector(selectBasicTransfer);

  const isUpgrade =
    plan?.planName === "registered-user" && payment?.customerKey;
  const displayClose = isUpgrade;

  const closeModal = () => {
    dispatch(setShowTrialsModal(false));
  };

  useEffect(() => {
    setOpen(isLoggedIn && showTrialsModal && pageAllowed);
  }, [isLoggedIn, showTrialsModal, pathName]);

  const handleCloseIcon = () => {
    const baseUrl = `${routes.RECEIVED_TRANSFERS}`;

    if (pathName.includes("/received-transfers")) {
      if (pathName.startsWith(baseUrl) && pathName.length > baseUrl.length) {
        return closeModal();
      } else {
        closeModal();
        return router.push(routes.WORKSPACES);
      }
    }

    if (pathName.includes("/project") && !basicTransfer) {
      closeModal();
      return router.push(routes.WORKSPACES);
    }

    return closeModal();
  };

  return (
    <Modal open={open} data-testid="dti-trials-modal" disableAutoFocus>
      <TrialsGridWrapper container>
        {displayClose && (
          <TrialsClosed>
            <CloseRoundedIcon
              data-testid="dti-trials-modal-close-btn"
              data-analytics="dti-trials-modal-close-btn"
              fontSize="small"
              onClick={handleCloseIcon}
            />
          </TrialsClosed>
        )}

        <TrialsContentContainer>
          <Grid container>
            <TrialsContainer item>
              <TrialsIconContainer>
                <TrialsIcon />
              </TrialsIconContainer>
            </TrialsContainer>

            <TrialsContainer item>
              <TrialsHeader isSender={isSender} isUpgrade={!!isUpgrade} />
            </TrialsContainer>

            <TrialsContainer item>
              <TrialsFeatures isUpgrade={!!isUpgrade} />
            </TrialsContainer>

            {!isUpgrade && (
              <TrialsContainer item data-testid="dti-trials-terms">
                <TrialsTerms isSender={isSender} />
              </TrialsContainer>
            )}

            <TrialsContainer
              item
              sx={{ marginTop: isUpgrade ? theme.spacing(2) : 0 }}
            >
              <TrialsCta plan={plan?.planName} payment={payment?.customerKey} />
            </TrialsContainer>
          </Grid>
        </TrialsContentContainer>
      </TrialsGridWrapper>
    </Modal>
  );
};

export default TrialsModal;
