import routes from "@/common/constants/routes";
import theme from "@/styles/theme";
import { Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";

interface TrialsTermsProps {
  isSender: boolean;
}

export const TrialsTerms = ({ isSender }: TrialsTermsProps) => {
  const { t } = useTranslation("trials");

  return (
    <>
      <Typography
        variant="bodyM"
        sx={{ margin: theme.spacing(2, 0), color: theme.palette.black[600] }}
      >
        {isSender ? (
          <Trans t={t}>
            trials.modal.terms.sender
            <Link
              href={routes.PRICING}
              data-testid="trials-modal-basic-link"
              data-analytics="trials-modal-basic-link"
              style={{
                color: theme.palette.grey[900],
                textDecoration: "underline",
              }}
            >
              link1
            </Link>
          </Trans>
        ) : (
          <Trans t={t}>
            trials.modal.terms.receiver
            <Link
              href={routes.PRICING}
              data-testid="trials-modal-solo-link"
              data-analytics="trials-modal-solo-link"
              style={{
                color: theme.palette.grey[900],
                textDecoration: "underline",
              }}
            >
              link
            </Link>
          </Trans>
        )}
      </Typography>
    </>
  );
};
