import {
  TrialsFeaturesContainer,
  TrialsFeaturesStyled,
} from "@/components/shared/trials/trials.styles";
import theme from "@/styles/theme";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import SelectAllRoundedIcon from "@mui/icons-material/SelectAllRounded";
import { Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

interface IsUpgrade {
  isUpgrade: boolean;
}

export const TrialsFeatures = ({ isUpgrade }: IsUpgrade) => {
  const { t } = useTranslation("trials");

  return (
    <TrialsFeaturesContainer>
      {isUpgrade && (
        <TrialsFeaturesStyled container>
          <FileUploadRoundedIcon
            fontSize="small"
            sx={{ marginRight: theme.spacing(2) }}
          />
          <Typography
            variant="bodyM"
            sx={{
              color: theme.palette.black[600],
              marginBottom: theme.spacing(2),
            }}
          >
            <Trans t={t}>
              trials.modal.features.one
              <Typography
                component="span"
                variant="inherit"
                sx={{
                  display: "inline",
                  fontWeight: 500,
                  color: "rgba(0,0,0,0.87)",
                }}
              >
                typo
              </Typography>
            </Trans>
          </Typography>
        </TrialsFeaturesStyled>
      )}

      <TrialsFeaturesStyled container>
        <BoltRoundedIcon
          fontSize="small"
          sx={{ marginRight: theme.spacing(2) }}
        />
        <Typography
          variant="bodyM"
          sx={{
            color: theme.palette.black[600],
            marginBottom: theme.spacing(2),
          }}
        >
          <Trans t={t}>
            trials.modal.features.two
            <Typography
              component="span"
              variant="inherit"
              sx={{
                display: "inline",
                fontWeight: 500,
                color: "rgba(0,0,0,0.87)",
              }}
            >
              typo
            </Typography>
          </Trans>
        </Typography>
      </TrialsFeaturesStyled>

      <TrialsFeaturesStyled container>
        <SelectAllRoundedIcon
          fontSize="small"
          sx={{ marginRight: theme.spacing(2) }}
        />
        <Typography variant="bodyM" sx={{ color: theme.palette.black[600] }}>
          <Trans t={t}>
            trials.modal.features.three
            <Typography
              component="span"
              variant="inherit"
              sx={{
                display: "inline",
                fontWeight: 500,
                color: "rgba(0,0,0,0.87)",
              }}
            >
              typo
            </Typography>
          </Trans>
        </Typography>
      </TrialsFeaturesStyled>

      {!isUpgrade && (
        <TrialsFeaturesStyled container>
          <FileUploadRoundedIcon
            fontSize="small"
            sx={{ marginRight: theme.spacing(2) }}
          />
          <Typography
            variant="bodyM"
            sx={{
              color: theme.palette.black[600],
              marginBottom: theme.spacing(2),
            }}
          >
            <Trans t={t}>
              trials.modal.features.one
              <Typography
                component="span"
                variant="inherit"
                sx={{
                  display: "inline",
                  fontWeight: 500,
                  color: "rgba(0,0,0,0.87)",
                }}
              >
                typo
              </Typography>
            </Trans>
          </Typography>
        </TrialsFeaturesStyled>
      )}
    </TrialsFeaturesContainer>
  );
};
